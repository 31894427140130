<template>
  <div class="project-edit">
    <div class="container fluid">
      <div class="card">
        <h1 class="mainTitle lineBottom p-3 mb-2">Edit Project</h1>
        <div class="p-1 sm-p-2 md-p-3">
          <edit-project-form
            type="agency"
            :projectId="$route.params.id"
            :isLoading.sync="isFormLoading"
            :project="project"
            :updateProjectAPI="projectAPI.updateProject"
          >
            <project-form-content
              isEdit
              type="agency"
              :formData.sync="project"
              :isLoading.sync="isFormLoading"
              :getProjectAPI="projectAPI.getProject"
              :getFacilitiesAPI="propertyAPI.getFacilities"
              :getAmenitiesAPI="propertyAPI.getAmenities"
              :getPropertyTagsAPI="propertyAPI.getPropertyTags"
              :getDevelopersAPI="developerAPI.getDevelopers"
            ></project-form-content>
          </edit-project-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { developer as developerAPI, property as propertyAPI } from "@/api";

import ProjectAPI from "@/modules/Project/api/project";
const projectAPI = new ProjectAPI("agency");

export default {
  components: {
    EditProjectForm: () =>
      import("@/modules/Project/components/EditProjectForm"),
    ProjectFormContent: () =>
      import("@/modules/Project/components/ProjectFormContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isFormLoading: false,
      project: {},

      projectAPI,
      developerAPI,
      propertyAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-edit {
  @extend %formDesign;
}
</style>
